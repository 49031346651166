import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider (https://login.konekto.be/auth/realms/konekto/.well-known/openid-configuration)
    issuer: 'https://login.konekto.be/auth/realms/konekto',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',

    postLogoutRedirectUri: window.location.origin,

    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'spurado',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email',

    strictDiscoveryDocumentValidation: false,

    showDebugInformation: false,

    timeoutFactor: 0.8,
};
